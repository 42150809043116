import './css/main.css';

import './css/fullpage.css';

import barba from '@barba/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';

barba.init({
  debug: true,
  timeout: 7000,
  transitions: [
    {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      // * MA added this beforeEntry bit
      beforeEnter: function beforeEnter(data) {},
      enter(data) {},
      after(data) {
        return gsap.from(data.next.container, {
          opacity: 0
        });
      },
      afterOnce(data) {},
      once(data) {}
    }
  ]
});

import(/* webpackChunkName: "menuScroll" */ './js/components/menuScroll.js').then(module => {
  const scrollIt = module.default;
  scrollIt();
});

function fpCheck(data) {
  let fullP = document.getElementById('fullpage');
  if (fullP != undefined && fullP != null) {
    import(/* webpackChunkName: "fullpage" */ './js/components/fullpage.js').then(module => {
      // const gridIt = module.default;
      // gridIt(data);
    });
  }
}

function scrollToTop() {
  // window.scrollTo({top: 0, behavior: 'smooth'});
  window.scrollTo({ top: 0 });
}

function gridCheck(data) {
  let grids = document.getElementsByClassName('masGrid');
  if (grids.length > 0) {
    import(/* webpackChunkName: "grid" */ './js/components/grid.js').then(module => {
      const gridIt = module.default;
      gridIt(data);
    });
  }
}

function projectsCheck(data) {
  import(/* webpackChunkName: "projectsMenu" */ './js/components/projectsMenu.js').then(module => {
    const projIt = module.default;
    projIt(data);
  });
}

function studioCheck(data) {
  import(/* webpackChunkName: "projectsMenu" */ './js/components/studioMenu.js').then(module => {
    const projIt = module.default;
    projIt(data);
  });
}

function galleryCheck(data) {
  let gallery = document.getElementsByClassName('gallery');
  if (gallery.length > 0) {
    import(/* webpackChunkName: "gallery" */ './js/components/gallery.js').then(module => {
      const galleryIt = module.default;
      galleryIt(data);
    });
  }
}

function mapCheck(data) {
  let map = document.getElementsByClassName('contactMap');
  if (map.length > 0) {
    import(/* webpackChunkName: "map" */ './js/components/map.js').then(module => {
      const mapIt = module.default;
      mapIt(data);
    });
  }
}

function projectImageHover() {
  let projectImageDiv = document.getElementsByClassName('projectImage');
  let projectText = document.getElementsByClassName('projImageHover');
  if (projectImageDiv.length > 0 && projectText.length > 0) {
    import(/* webpackChunkName: "projectImageHover" */ './js/components/projectHover.js').then(module => {
      const projHovIt = module.default;
      projHovIt();
    });
  }
}

function projIndexList() {
  let listItems = document.getElementsByClassName('projectIndexListItems');
  if (listItems.length > 0) {
    import(/* webpackChunkName: "projIndexList" */ './js/components/projIndexList.js').then(module => {
      const projListIt = module.default;
      projListIt();
    });
  }
}

function partnerList() {
  let listItemsPartner = document.getElementsByClassName('partnerListData');
  if (listItemsPartner.length > 0) {
    import(/* webpackChunkName: "projIndexList" */ './js/components/partnerList.js').then(module => {
      const partnerListIt = module.default;
      partnerListIt();
    });
  }
}

function splash(data) {
  import(/* webpackChunkName: "splash" */ './js/components/splash.js').then(module => {
    const splashIt = module.default;
    splashIt(data);
  });
}

let searchIcon = document.getElementById('searchIcon');
searchIcon.addEventListener('click', () => {
  toggleSearch('open');
});

function toggleSearch(goState) {
  import(/* webpackChunkName: "searchToggle" */ './js/components/searchToggle.js').then(module => {
    const searchIt = module.default;
    searchIt(goState);
  });
}

function hamburgerToggle(preset) {
  import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
      const toggle = module.default;
      toggle(preset);
  });
}

function projfilterToggle(preset) {
  import(/* webpackChunkName: "projFilterToggle" */ "./js/components/projFilterMenu.js").then(module => {
      const toggleProj = module.default;
      toggleProj(preset);
  });
}

function projCheck() {
  let projFilters = document.getElementsByClassName('projFilters ');
  if (projFilters.length > 0) {
    let projOpen = document.getElementById("openProjFilters");
      projOpen.addEventListener("click", function() {projfilterToggle()});

    let projCloser = document.getElementById("projFilterCloser");
    projCloser.addEventListener("click", function() {projfilterToggle('c')});
  }
}

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});


barba.hooks.once(data => {
  splash(data);
  projectsCheck(data);
  studioCheck(data);
  galleryCheck(data);
  // reloadSprig();
  projectImageHover();
  projIndexList();
  projCheck();
  partnerList();
  // subMenuToggle('open');
  
});

barba.hooks.afterOnce(data => {
  mapCheck(data);
  gridCheck(data);
  setTimeout(() => {
    gridCheck(data);
  }, 500);
});

barba.hooks.before(data => {
 
  hamburgerToggle('c');
  projfilterToggle('c');
  fpCheck(data);
  
});

barba.hooks.enter(data => {
  projectsCheck(data);
  studioCheck(data);
});

barba.hooks.beforeEnter(data => {
  scrollToTop();
  
});

barba.hooks.after(data => {
  toggleSearch('close');
  mapCheck(data);
  gridCheck(data);
  fpCheck(data);
  galleryCheck(data);
  // reloadSprig();
  projectImageHover();
  projIndexList();
  projCheck();
  partnerList();
  // subMenuToggle('open');
  
});
